@font-face {
    font-family: "MosseBlack";
    src: local("MosseBlack"),
    url("../../../../public/fonts/mosse/mosse-black.ttf") format("truetype");
    font-weight: bold;
}
@font-face {
    font-family: "MosseBook";
    src: local("MosseBook"),
    url("../../../../public/fonts/mosse/mosse-book.ttf") format("truetype");
    font-weight: bold;
}
@font-face {
    font-family: "MosseLight";
    src: local("MosseLight"),
    url("../../../../public/fonts/mosse/mosse-light.ttf") format("truetype");
    font-weight: bold;
}

body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.hunter-app {
    height: 100vh;
    font-family: MosseBlack;
    margin: 0;
    padding: 0;
    background: linear-gradient( rgba(255, 255, 255, 0.60), rgba(57, 77, 61, 0.99)), url('../../../../public/img/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: top;
    overflow: hidden;
}

.logo-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 25px;
    width: auto;
}

.logo > img {
    width: 250px;
    height: auto;
}

.project-name {
    font-size: 41px;
    color: #F5742A;
    text-shadow: 0 1px 0 #363636, 0 2px 0 #4b4a4a, 0 3px 0 #232222, 0 4px 0 #090909, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15);
}

.rep-board {
    font-size: 32px;
    text-align: center;
    color: #F5742A;
    text-shadow: 0 1px 0 #363636, 0 2px 0 #4b4a4a, 0 3px 0 #232222, 0 4px 0 #090909, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15);
}

.header-right {
    width: 100%;
    height: 135px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header {
    display: flex;
    align-items: center;
}

.hunter-header-content {
    position: relative;
    border-bottom: 2px solid;
    z-index: 1;
}

.circle-border {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    bottom: -32px;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    border: 2px solid;
    z-index: 0;
}

.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background: #f5742a;
    border-radius: 50%;
    bottom: -30px;;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 30px;
    line-height: 60px;
    z-index: 2;
}

.icon-logo {
    width: 80%;
    height: auto;
}

.main {
    display: flex;
}

.obtainedCards {
    width: 40%;
    padding: 15px 25px;
    box-sizing: border-box;
}

.reputation {
    width: 100%;
}

.reputationInfo {
    display: flex;
    padding: 55px 25px;
    height: auto;
}
.reputationButtons {
    display: flex;
    justify-content: space-between;
}
.pointsBtn {
    font-size: 0.9em;
    width: 49.7%;
    font-family: MosseBlack;
    height: 45px;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
}
.addPointBtn {
    background: #2b675b;
    text-shadow: 1px 1px 1px #2b2424, 0 0 0.1em #121211, 0 0 0.5em black;
    color: #5f5fb2;

}
.burnPointBtn {
    background: #2b675b;
    text-shadow: 1px 1px 1px #2b2424, 0 0 0.1em #121211, 0 0 0.5em black;
    color: #c42236;
}

.pointsInput {
    width: 100%;
    height: 35px;
    box-sizing: border-box;
    margin-bottom: 8px;
    text-align: center;
    font-family: MosseBlack;
    padding: 4px 5px;
    font-size: 18px;
    border-width: 4px;
    border-style: ridge;
    border-color: rgb(48, 38, 27);
}

.points-adder {
    display: flex;
    flex-flow: column;
}

.hunter-bio {
    text-align: center;
    width: 350px;
}

.hunter-panel {
    width: 350px;
}

/* Remove Arrows */
.pointsInput::-webkit-outer-spin-button,
.pointsInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.pointsInput[type=number] {
    -moz-appearance: textfield;
}

.hunter-photo {
    border-radius: 100px;
    width: 150px;
    height: auto;
    margin-bottom: 10px;
    box-shadow: 1px 1px 2px #000, 0 0 1em #4c4cc9, 0 0 .2em blue;
}

.hunter-bio div {
    margin-bottom: 5px;
}

.orange {
    color: #f5742a;
    text-shadow: 1px 1px 2px black, 0 0 1em blue, 0 0 0.2em blue;
}

.red {
    color: #e51b32;
    text-shadow: 1px 1px 2px black, 0 0 1em #171759, 0 0 0.2em #2828b6;
}

.bio-label {
    font-size: 24px;
    color: #1A1A1A;
}

.cardsTitle {
    font-size: 24px;
    color: #F5742A;
    text-shadow: 0 1px 0 #363636, 0 2px 0 #4b4a4a, 0 3px 0 #232222, 0 4px 0 #090909, 0 5px 0 #aaa, 0 6px 1px rgb(0 0 0 / 10%), 0 0 5px rgb(0 0 0 / 10%), 0 1px 3px rgb(0 0 0 / 30%), 0 3px 5px rgb(0 0 0 / 20%), 0 5px 10px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 20%), 0 20px 20px rgb(0 0 0 / 15%);
    text-align: center;
    margin-bottom: 15px;
}

.cardsGrid {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}
.cards {
    width: 33.3%;
    display: flex;
    margin-bottom: 15px;
}
.card {
    width: 145px;
    height: auto;
}

.addAbility {
    width: 100%;
    font-family: MosseBlack;
    height: 35px;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    background: #F5742A;
    border: 1px solid #1A1A1A;
    color: #1A1A1A;
    margin-top: 35px;
}

.card-obtained {
    cursor: pointer;
    box-shadow: 1px 1px 2px black, 0 0 1em blue, 0 0 0.2em blue;
}

.availablePoints {
    text-align: center;
    margin-bottom: 10px;
}

.reputation-data {
    display: flex;
    flex-flow: column;
    align-items: center;
}

.mainMissions {
    display: flex;
    flex-flow: column;
}

.background-card {
    width: 350px;
    border: 4px ridge #30261B;
    padding: 15px;
    margin-top: 5px;
    background: linear-gradient(rgba(62, 101, 86, 0.95), rgba(23, 23, 54, 0.95)), url('../img/list_bg.png');
    background-size: cover;
    background-position: center;
    text-align: center;
    box-sizing: border-box;
}

.mission {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-shadow: 1px 1px 1px #2b2424, 0 0 0.1em #121211, 0 0 0.5em black;
    color: #f5742a;
}

.mission-done-btn {
    font-family: MosseBlack;
    cursor: pointer;
    height: 28px;
    padding: 0 10px;
    box-sizing: border-box;
    background: #F5742A;
    border: 1px solid #1A1A1A;
    color: #1A1A1A;
    vertical-align: middle;
}
.mission-done-btn:disabled {
    background: #a19d96;
    color: #090909;
}

.addAbility {
    box-shadow: 1px 1px 2px black, 0 0 1em blue, 0 0 0.2em blue;
}

.flex {
    display: flex;
    justify-content: space-around;
}

.points-and-btn {
    display: flex;
    align-items: center;
}

.availablePoints {
    font-size: 21px;
}

.mt-10 {
    margin-top: 20px;
}

.mission-right {
    position: relative;
    margin-top: 15px;
}

.failed-mission {
    margin-top: 15px;
}

.addSkillBtn {
    width: 100%;
    background: url('../img/addskillbg.png');
}

.Toastify__toast {
    font-family: MosseBlack !important;
    text-shadow: 1px 1px 1px #2b2424, 0 0 0.1em #121211, 0 0 0.5em black;
    color: #f5742a;
}
.btn-disabled {
    background: #a19d96;
    color: #090909;
}

.popup-content {
    display: flex;
    justify-content: center;
    text-align: center;
    background: linear-gradient(rgba(62, 101, 86, 0.10), rgba(23, 23, 54, 0.95)), url('../img/list_bg.png');
    background-size: contain;
    background-position: center;
    padding: 10px;
    border: 4px ridge #30261B;
}

.popup-content h3,
.popup-content p {
    margin-top: 0;
    margin-bottom: 5px;
    color: #F5742A;
    width: 500px;
    font-family: "MosseBlack";
}
.popup-content p {
    font-size: 18px;
    font-family: monospace;
}
.mb-15 {
    margin-bottom: 15px;
}

.purple-shadow {
    text-shadow: 1px 1px 2px #000, 0 0 1em blue, 0 0 0.2em blue;
}

.skills-list {
    font-size: 16px;
    text-align: center;
}

.skill-shadow {
    color: #f5742a;
    text-shadow: 1px 1px 1px #2b2424, 0 0 0.1em #121211, 0 0 0.5em #000;
}
.skill-desc {
    font-family: monospace;
    font-size: 16px;
    margin: 0;
}

hr {
    border: none;
    border-top: 5px double #333;
    color: #333;
    overflow: visible;
    text-align: center;
    height: 5px;
}

hr::after {
    content: '🛸';
    padding: 0 4px;
    position: relative;
    top: -13px;
}

.answer-text {
    word-break: break-word;
}

.answer-block {
    height: 208px;
}
.question {
    border: 4px ridge #30261B;
    padding: 2px 4px;
    font-size: 18px;
}

.answer-text {
    text-shadow: 1px 1px 1px #2b2424, 0 0 0.1em #121211, 0 0 0.5em black;
    color: #ACCC12;
}

.hunter-chat, .mainMissions {
    padding-top: 20px;
}

.reputation-action {
    width: 100%;
    padding: 0 20px;
}

.increment-board {
    text-align: center;
    margin: 0 45px;
    padding: 10px;
    border: 4px ridge #30261B;
}


.quote-item {
    color: white;
    font-family: monospace;
}

blockquote {
    margin: 0;
    padding: 0;
}
